import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  ClearRefinements,
  Configure,
  CurrentRefinements,
  Highlight,
  Hits,
  InstantSearch,
  Pagination,
  RefinementList,
  SearchBox,
} from 'react-instantsearch-hooks-web';
import { ToggleRefinement } from 'react-instantsearch-hooks-web';
import { RangeInput } from 'react-instantsearch-hooks-web';
import { Panel } from './Panel';

import { Hit } from 'instantsearch.js';

import './App.css';

import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import instantsearch from 'instantsearch.js';

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: "CvJtP5kGwvHIkzYAbOImHCEBFnE7KfaM", // Be sure to use the search-only-api-key
    nodes: [
      {
        host: "lv47gncpz3abt09dp-1.a1.typesense.net",
        port: 443,
        protocol: "https"
      }
    ]
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    query_by: "body, source, components, created_by_string, created_at, enhancement_source, enhancement_summarisation,enhancement_entity_detection,enhancement_sentiment_analysis,enhancement_sentiment_analysis_collection,enhancement_classification,enhancement_full_summary,enhancement_action_points,enhancement_important_phrases,enhancement_topic_detection"
  }
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

const search = instantsearch({
  searchClient,
  indexName: "dashboard_data_v2"
});

export function App() {
  return (
    <div>
      <header className="header">
        <h1 className="header-title">
          <a href="/">Dashboard Data</a>
        </h1>
        <p className="header-subtitle">
          Search
        </p>
      </header>

      <div className="ais-InstantSearch">
        <InstantSearch searchClient={searchClient} indexName="dashboard_data_v2">
          <Configure hitsPerPage={8} />
          <div className="search-panel">
            <div className="search-panel__filters">
            <ClearRefinements />
            <h3>Source</h3> 
            <RefinementList attribute="source"/>
            <h3>Enhancement source</h3>
            <RefinementList attribute="enhancement_source"/>
            
            <h3>Sentiment Analysis</h3>
            <RefinementList attribute="enhancement_sentiment_analysis_collection"/>
            <h3>Components</h3>
            <RefinementList attribute="components"/>
            <h3>Created by</h3>
            <RefinementList attribute="created_by_string"/>
            <h3>Username</h3>
            <RefinementList attribute="user_name"/>
            <h3>Team</h3>
            <RefinementList attribute="is_team_input"/>
            <h3>Priority</h3>
            <RefinementList attribute="priority"/>
            <h3>Happiness Range</h3>
            <RefinementList attribute="happiness_range"/>
            <h3>Happiness Score</h3>
            <RefinementList attribute="happiness_score"/>
            <h3>Marked</h3>
            <RefinementList attribute="marked"/>
            
            <Configure hitsPerPage={8} />
            </div>
           

            <div className="search-panel__results">
              <SearchBox placeholder="" className="searchbox" />
              <CurrentRefinements/>
              <Hits hitComponent={Hit} />

              <div className="pagination">
                <Pagination />
              </div>
            </div>
          </div>
        </InstantSearch>
      </div>
    </div>
  );
}

type HitProps = {
  hit: Hit;
};

function extractSentiment(text: string): string {
  const regex = /\b(Positive|Negative):\s*([\d.]+|%)/g;
  console.log(text);
  console.log(".----.--.-.-.-.-------");
  let match: RegExpExecArray | null;
  let positive = 0;
  let negative = 0;
  while ((match = regex.exec(text)) !== null) {
    const sentiment = match[1];
    console.log("Sentiment: " + match[1]);
    console.log("Percentage: " + match[2]);
    const percentage = match[2];
    if (sentiment === "Positive") {
      if (percentage === "100%") {
        positive = 1;
        break;
      } else if (parseFloat(percentage) >= 55) {
        positive++;
      }
    } else if (sentiment === "Negative" && parseFloat(percentage) >= 55) {
      negative++;
    }
  }
  console.log("positive: " + positive);
  console.log("negative: " + negative);
  if (positive > 0 && negative > 0) {
    if (positive > negative) {
      return "Positive";
    } else if (negative > positive) {
      return "Negative";
    }
  } else if (positive > 0) {
    return "Positive";
  } else if (negative > 0) {
    return "Negative";
  }
  return "Neutral";
}





function Hit({ hit }: HitProps) {
  return (
    <article>
      {<p>
        <b>Body</b><br/>
        {hit.body && <Highlight attribute="body" hit={hit} />}
        {!hit.body && ""}
      </p>}
      <p>
        <b>Components</b> <br></br> <Highlight attribute="components" hit={hit} />
        {!hit.components && ""}
      </p>
      <p></p>
      <p>
       <b>enhancement_action_points</b><br></br> <Highlight attribute="enhancement_action_points" hit={hit} />
       {!hit.enhancement_action_points && ""}
      </p>
      <p></p>
      <p></p>
      <p>
       <b>enhancement_source</b><br></br> <Highlight attribute="enhancement_source" hit={hit} />
       {!hit.enhancement_source && ""}
      </p>
      <p></p>
      <p></p>
      <p>
       <b>enhancement_summarisation</b><br></br> <Highlight attribute="enhancement_summarisation" hit={hit} />
       {!hit.enhancement_summarisation && ""}
      </p>
      <p></p>
      <p></p>
      <p>
       <b>enhancement_entity_detection</b><br></br> <Highlight attribute="enhancement_entity_detection" hit={hit} />
       {!hit.enhancement_entity_detection && ""}
      </p>
      <p></p>
      <p></p>
      <p>
       <b>enhancement_sentiment_analysis</b><br></br> <Highlight attribute="enhancement_sentiment_analysis" hit={hit} />
       {!hit.enhancement_sentiment_analysis && ""}
      </p>
      <p></p>
      <p></p>
      <p>
       <b>enhancement_sentiment_analysis_collection</b><br></br> <Highlight attribute="enhancement_sentiment_analysis_collection" hit={hit} />
       {!hit.enhancement_sentiment_analysis_collection && ""}
      </p>
      <p></p>
      <p></p>
      <p>
       <b>enhancement_important_phrases</b><br></br> <Highlight attribute="enhancement_important_phrases" hit={hit} />
       {!hit.enhancement_important_phrases && ""}
      </p>
      <p></p>
      <p></p>
      <p>
       <b>enhancement_full_summary</b><br></br> <Highlight attribute="enhancement_full_summary" hit={hit} />
       {!hit.enhancement_full_summary && ""}
      </p>
      <p></p>
      <p></p>
      <p>
       <b>enhancement_topic_detection</b><br></br> <Highlight attribute="enhancement_topic_detection" hit={hit} />
       {!hit.enhancement_topic_detection && ""}
      </p>
      <p></p>
      <p></p>
      <p>
        <b>source</b><br></br> <Highlight attribute="source" hit={hit} />
        {!hit.source && "  "}
      </p>
      <p></p>
      <p>
        {!hit.created_by_string && "  "}
        <b>created_by_string:</b> <Highlight attribute="created_by_string" hit={hit} />
      </p>
      <p></p>
      <p>
        <b>created_at</b><br></br> <Highlight attribute="created_at" hit={hit} />
        {!hit.created_at && "  "}
      </p>
      <p></p>
      <p>
        <b>user_name</b><br></br> <Highlight attribute="user_name" hit={hit} />
        {!hit.user_name && "  "}
      </p>
      <p></p>
    </article>
  );
}
